.normal {
  color: white;
}

.warning {
  color: yellow;
}

.danger {
  color: red;
}
