
.chipValue {
    font-size: 1.7rem;
    font-weight: bold;
}

.chipValue.white, .chipValue.gold {
    color: black;
}

.pokerChip {
    font-size:1rem;
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    width:5rem;
    height:5rem;
    background:#555;
    border-radius:50%;
    border:.5rem dashed white;
    transition:all .5s ease;
    backface-visibility:hidden;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.pokerChip.ng-move, .pokerChip.ng-enter, .pokerChip.ng-leave {
    -webkit-transition:all ease 1.3s;
    transition:all ease 1.3s;
}

.pokerChip.ng-leave.ng-leave-active,
.pokerChip.ng-move,
.pokerChip.ng-enter {
    margin-left: -1000px;
    margin-right: 1000px;
    transform:rotate(-390deg);
}

.pokerChip.ng-leave,
.pokerChip.ng-move.ng-move-active,
.pokerChip.ng-enter.ng-enter-active {
    margin-left: 0;
    margin-right: 0;
    transform:rotate(90deg);
}

.pokerChip.white, .pokerChip.gold {
    border:.5em dashed black;
}

.pokerChip.flat{
    box-shadow:0 0 0 .2rem #fff;
}

.pokerChip.iso{
    transform: perspective(3000px) rotateX(55deg) rotateZ(-40deg);
    box-shadow:
    -1px 1px 0 #555,
    -2px 2px 0 #555,
    -3px 3px 0 #555,
    -4px 4px 0 #555,
    -5px 5px 0 #555,
    -6px 6px 10px #555;
}

.pokerChip.flat:hover{
    transform:rotate(90deg);
}

.pokerChip.iso:hover{
    transform: perspective(3000px) rotateX(55deg) rotateZ(-40deg) translateZ(.5em);
}

.antiCircle {
    position: absolute;
    opacity: .90;
}
